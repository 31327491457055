<script setup lang="ts">
  import { PlanType, PolicyType, SnpType } from '~/generated/api-clients-generated'
import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

const props = defineProps<{
  quote: QuotedPlanVm,
  id: string
}>()

const { getId } = Id.use(props.id)

  const isMapd = computed(() => props.quote.type === PlanType.MAPD)
  const isDsnp = computed(() => props.quote.snpType === SnpType.DualEligible)

const policyTypeTooltipKey = computed(() => `PolicyType${_upperFirst(PolicyType[props.quote.policyType].toString().toLowerCase())}`)
const policyTypeTooltipId = computed(() => `${getId.value()}-pt-tooltip`)

const { show: showTooltip } = ToolTip.use()
const { getTooltipContent } = Cms.use()

const onClickPolicyType = () => {
  showTooltip(policyTypeTooltipId.value)
}


const { content: policyTypeTooltip } = await getTooltipContent(policyTypeTooltipKey.value)
const { content: dsnpTooltip } = await getTooltipContent(`DsnpPlan`)
</script>

<template>
  <div class="pt-badges">
    <QuoteBadge
      title-case
      v-if="isMapd"
      @click="onClickPolicyType"
      :text="PolicyType[quote.details.policyType!].toString()"
      :clickable="!_isNil(policyTypeTooltip)"
      variant="white"
    >
    </QuoteBadge>
    <PxTooltip v-if="!_isNil(policyTypeTooltip)"
               :title="policyTypeTooltip.title"
               class="-ms-2"
               :text="null"
               :body="policyTypeTooltip.body" :id="policyTypeTooltipId" />
    <QuoteBadge v-if="isMapd && isDsnp"
                text="DSNP"
                variant="white"
                clickable
                @click="() => showTooltip(`${getId()}-dsnp-tooltip`)" />
    <PxTooltip v-if="isDsnp"
               :title="dsnpTooltip.title"
               :text="null"
               :body="dsnpTooltip.body"
               :id="`${getId()}-dsnp-tooltip`" />
  </div>
</template>

<style scoped>
  .pt-badges {
    @apply flex gap-x-2;
  }

  .pt-badges:deep(.tooltip) {
    .target-text {
      @apply hidden;
    }
  }
</style>